import { ProjectStatuses, ProjectFeatureFlags } from "@/components/constants.js"

export default {
  name: {
    component: "TextFieldSearchFilter",
  },
  exid: {
    component: "TextFieldSearchFilter",
  },
  cameras: {
    component: "TextFieldSearchFilter",
  },
  status: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ value: "", name: "All" }, ...ProjectStatuses],
    },
  },
  featureFlags: {
    component: "SelectSearchFilter",
    attributes: {
      items: ProjectFeatureFlags,
      multiple: true,
    },
  },
}
