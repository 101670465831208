import { ProjectStatusMap } from "@/components/constants.js"

export default {
  name: "ProjectUtils",
  methods: {
    getProjectStatusColor(status) {
      return ProjectStatusMap[status]?.class || ""
    },
    getProjectStatusText(status) {
      return ProjectStatusMap[status]?.value || status
    },
  },
}
