export default ({ $config }) => {
  return [
    {
      value: "name",
      text: "Name",
      width: 150,
      sortable: true,
      filterable: true,
    },
    {
      value: "status",
      text: "Status",
      width: 85,
      sortable: true,
    },
    {
      value: "exid",
      text: "Project ID",
      width: 100,
      sortable: true,
      filterable: true,
    },
    {
      value: "zohoId",
      text: "Zoho",
      sortable: false,
      filterable: false,
      width: 60,
      align: "center",
      toStringFn: (item, key) =>
        item[key]
          ? `${$config.public.zohoCrmUrl}/tab/CustomModule12/${item[key]}`
          : "-",
    },
    {
      value: "demo",
      text: "Demo",
      sortable: false,
      filterable: false,
      width: 40,
      align: "center",
    },
    {
      value: "timezone",
      text: "Timezone",
      sortable: true,
      filterable: true,
      width: 136,
    },
    {
      value: "cameras",
      text: "Cameras",
      width: 150,
      sortable: false,
      sort: (a, b) => {
        return a.length > b.length ? 1 : -1
      },
      toStringFn: (item, key) =>
        item[key]?.map((camera) => camera.name).join(",") || "-",
    },
    {
      value: "featureFlags",
      text: "Feature Flags",
      sortable: true,
      width: 200,
    },
    {
      value: "insertedAt",
      text: "Project Start Date",
      width: 150,
      sortable: true,
    },
  ]
}
